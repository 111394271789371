<template>
  <div>
    <!-- Tags -->
    <div class="py-3">
      <x-periodo-fiscal-tag></x-periodo-fiscal-tag>
      <b-badge variant="success" class="mr-2">Mes: {{ getMonthById(traspaso.mes).value }}</b-badge>
      <b-badge variant="dark">Folio: {{ traspaso.folio }}</b-badge>
    </div>

    <!-- Muestra una alerta mensajes y errores generale -->
    <portal-target name="notificationTraspasosSidebarAlert" />

    <!-- Muestra la lista de errores encontrados en el archivo -->
    <b-alert show variant="danger" v-for="row in errors.rows" :key="row.id" class="py-2">
      <h6 class="text-danger">Columna: {{ row.attribute }}</h6>
      <ul>
        <li v-for="error in row.errors" :key="error.id">{{ error }}</li>
      </ul>
    </b-alert>

    <!-- Formulario -->
    <loading v-if="loading"></loading>
    <b-form v-else @submit.prevent="submit">
      <!-- Archivo layout -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Archivo layout
            <span class="text-danger">*</span>
          </label>
        </template>

        <b-form-file
          size="sm"
          v-model="payload.file"
          :state="Boolean(payload.file)"
          placeholder="Selecciona el archivo layout excel...">
          <template slot="file-name" slot-scope="{ names }">
            <b-badge variant="dark">{{ names[0] }}</b-badge>
          </template>
        </b-form-file>
      </b-form-group>

      <!-- Partida o proyecto -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Partida o propuesta
          </label>
        </template>

        <b-form-radio
          v-model="partida_propuestas"
          name="partida_propuestas"
          value="partida"
        >
          Partida
        </b-form-radio>
        <b-form-radio
          v-model="partida_propuestas"
          name="partida_propuestas"
          value="propuestas"
        >
          Propuesta
        </b-form-radio>
      </b-form-group>

      <!-- Sobreescribir -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Archivo previamente importado
          </label>
        </template>

        <b-form-checkbox v-model="payload.overwrite" name="overwrite" switch>
          Sobreescribir
        </b-form-checkbox>
      </b-form-group>

      <!-- Save file -->
      <b-form-group>
        <template #label>
          <label class="small font-weight-bolder">
            Guardar archivo
          </label>
        </template>

        <b-form-checkbox v-model="payload.save_file" name="save_file" switch>
          Guardar archivo
        </b-form-checkbox>
      </b-form-group>

      <!-- <loading message="Importando pólizas..."></loading> -->
      <div class="my-2 d-flex">
        <slot name="btn-cancel-place" v-bind="{ clearForm }"></slot>

        <b-button
          type="submit"
          variant="success"
          size="sm"
          class="ml-auto"
          :disabled="!isFormValid"
        >
          Subir
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const payload = () => ({
  file: null,
  save_file: false,
  overwrite: false
})

const errors = () => ({
  rows: []
})

export default {
  name: 'TraspasosImport',

  props: {
    traspaso: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      payload: payload(),

      errors: errors(),

      loading: false,

      partida_propuestas: 'partida'
    }
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById']),

    isFormValid () {
      return [Boolean(this.payload.file)].every(e => e)
    }
  },

  methods: {
    async submit () {
      this.errors = errors()

      this.loading = true
      const getval = await this.$store.dispatch('traspasoModule/import', {
        file: this.payload.file,
        save_file: this.payload.save_file,
        overwrite: this.payload.overwrite,

        traspaso_id: this.traspaso.id,
        periodo_fiscal_id: this.traspaso.periodo_fiscal_id,

        partida_propuestas: this.partida_propuestas
      })
      this.loading = false

      //
      // Borra el formulario, exista error o no
      this.clearForm()

      if (getval.error) {
        //
        // Obtiene los errores, si es que los hay
        this.errors.rows = getval.data

        this.$alert(
          getval,
          'Importación de layout para traspaso.',
          'notificationTraspasosSidebarAlert',
          { status: 'danger' }
        )
      }

      //
      // Muestra mensaje de éxito y emite que ha terminado el proceso
      this.showMessage(getval.message)
      this.emitResult(getval)
    },

    clearForm () {
      this.payload = payload()
      this.errors = errors()
      this.$clearAlert()
    },

    emitResult (data) {
      this.$emit('on-result', data)
    },

    showMessage (msg) {
      this.$bvModal.msgBoxOk(msg, {
        title: 'Creación de traspasos.',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    }
  }
}
</script>

<style>
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Explorar';
}
</style>
